<template>
  <div>
    <Loading v-show="loading"></Loading>
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-row>
        <v-col
          cols="6"
        >
          <label>店舗名</label>
          <v-select
            item-text="siteName"
            item-value="siteId"
            :items="sitelist"
            label="Select SC"
            :rules="[(v) => !!v || 'required']"
            outlined
            @change="selectSc"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <input
            type="file"
            name="file"
            @change="selectedFile"
          >
        </v-col>
        <v-col
          v-if="fileCheckError"
          cols="12"
        >
          <span style="color:red">Please select a csv file </span>
        </v-col>
        <v-col cols="12">
          <v-btn
            style="text-transform: none"
            color="primary"
            @click="importCsv"
          >
            CSVファイル送信
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'
import store from '@/store'
import importCsvStoreModule from './importCsvStoreModule'
import { onUnmounted } from '@vue/composition-api'
import Loading from '@/views/components/load/Loading.vue'

const USER_APP_STORE_MODULE_NAME = 'app-importCsv'

export default {
  components: {
    Loading,
  },
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
  },
  methods: {
    selectedFile(e) {
      // 選択された File の情報を保存しておく
      e.preventDefault()
      const file = e.target.files[0]
      this.uploadFile = file
      this.fileCheckError = false
    },
    selectSc(siteId) {
      this.selectedSiteId = siteId
    },
    importCsv() {
      this.fileCheckError = false
      const isFormValid = this.$refs.form.validate()

      if (!isFormValid) return

      if (this.uploadFile === null) {
        this.fileCheckError = true

        return
      }

      this.loading = true
      const formData = new FormData()
      formData.append('upload', this.uploadFile)
      formData.append('siteId', this.selectedSiteId)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      store
        .dispatch('app-importCsv/fetchData', formData, config)
        .then(response => {
          console.log(response)
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.$router.push({ name: 'error-login' })
        })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, importCsvStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // 拠点情報取得
    const ecryptedData = sessionStorage.getItem('siteList')

    // 復号する
    const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
    const siteList = JSON.parse(decrypted.toString(crypto.enc.Utf8))
    const scList = []
    siteList.forEach(element => {
      if (element.id === 8 || element.id === 2 || element.id === 3 || element.id === 11 || element.id === 12 || element.id === 14 || element.id === 15 || element.id === 16) {
        const json = {
          siteName: element.name,
          siteId: element.id,
        }

        scList.push(json)
      }
    })

    return {
      sitelist: scList,
      siteId: '',
      siteName: '',
      loading: false,
      uploadFile: null,
      fileCheckError: false,
      selectedSiteId: '',
    }
  },
}
</script>
